import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirect } from 'utility/routing/redirect-component';
import { useDebounceEffect } from 'hooks/use-debounce-effect';

export function EnsureTrailingSlash() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectWrapper = (path) => {
      redirect(path, navigate);
    };

    const filteredPathName = location.pathname.split('/').filter((x) => x && x.trim() !== '');
    if (filteredPathName.length === 0) {
      return;
    }
    const isFile = filteredPathName[filteredPathName.length - 1]?.includes('.');
    if (isFile) {
      console.log('isFile');

      if (location.pathname.endsWith('/')) {
        redirectWrapper(
          '/' +
            location.pathname
              .split('/')
              .filter((x) => x && x.trim() !== '')
              .join('/') +
            location.search +
            location.hash,
        );
      }
    } else if (!location.pathname.match(/.\/($|\?|#)/m)) {
      console.log('isNotFile');

      redirectWrapper(
        '/' +
          location.pathname
            .split('/')
            .filter((x) => x && x.trim() !== '')
            .join('/') +
          '/' +
          location.search +
          location.hash,
      );
    }
  }, [location]);
  return <></>;
}
